<template>
  <div class="d-flex flex-column" style="background-color: #F9F9F9;">
    <div data-app :class="`d-flex flex-column ${isWide ? 'w-50 mx-auto my-25' : isMobile ? 'm-2' : 'w-75 mx-auto my-15'}`" v-if="eventInfo && drinkList">
      <span :class="`font-bold ${isWide ? 'font-30' : isMobile ? 'm-5 font-20' : 'font-25'}`">{{ $t(`${isUpdate ? 'edit_drinks_menu' : 'add_drinks_menu'}`) }}</span>
      <div class="d-flex flex-column" v-if="drinkList.length === 0 || tagList.length > 0">
        <div :class="`d-flex justify-content-between vertical-center ${isMobile ? 'm-5' : 'mt-10'}`">
          <span :class="`font-bold ${isWide ? 'font-20' : isMobile ? 'font-12' : 'font-15'}`">{{ $t(`tag`) }}</span>
          <v-btn :class="`vertical-center ${isMobile ? 'button-small' : 'button-normal'}`" @click="visibleSetTagDialog = true">
            <i :class="`${isMobile ? 'mx-3' : 'mx-5'} fa fa-plus font-12 color-white`" />
            <span :class="`${isMobile? 'mr-3' : 'mr-5'}`">{{ $t('set_new_tag') }}</span>
          </v-btn>
        </div>
        <div :class="`box-white d-flex justify-content-between vertical-center ${isWide ? 'mt-5 px-10 py-5' : isMobile ? 'mx-5 p-5' : 'mt-3 px-10 py-5'}`">
          <div class="w-100 input-normal d-flex">
            <span :class="`${isMobile? 'font-12' : 'font-15'}`">{{ selectedTag }}</span>
          </div>
          <v-btn class="ml-3 button-small" icon @click="keywordTag = ''; visibleSelectTagDialog = true;">
            <i :class="`${isMobile ? 'font-12' : 'font-15'} fa fa-search color-white`" />
          </v-btn>
        </div>
      </div>
      <span :class="`mt-10 font-bold ${isWide ? 'font-30' : isMobile ? 'font-20' : 'font-25'}`" v-if="selectedTag === eventInfo.tag">{{ $t(drinkId ? 'edit_drinks_menu' : 'add_drinks_menu') }}</span>
      <div :class="`box-white d-flex flex-column ${isWide ? 'mt-5 p-10' : isMobile ? 'mt-2 mx-5 p-5' : 'mt-3 p-10'}`" v-if="selectedTag === eventInfo.tag">
        <span :class="`ml-3 mt-5 font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('event_name') }}</span>
        <input class="mt-2 input-normal" v-model="eventInfo.name" disabled />
        <span :class="`ml-3 mt-5 font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('category') }}</span>
        <div class="mt-2 row">
          <div :class="isMobile && index === categories.length - 1 ? 'col-12' : 'col-xl-4 col-6'" v-for="(item, index) in categories" :key="(item, index)">
            <div :id="`divCategory_${index}`" :class="`my-1 category-${item === category ? 'selected' : 'normal'} vertical-center`" @click="category = item; clearQuantityMap();">
              <img :src="getDrinkIcon(item)" :height="20" />
              <span class="ml-5 font-12">{{ item }}</span>
            </div>
          </div>
        </div>
        <div :class="`d-flex ${isWide ? 'vertical-center' : 'flex-column'}`" v-if="isLongDrinks()">
          <div :class="`mt-5 d-flex flex-column ${isWide ? 'w-50 mr-2' : 'w-100'}`">
            <span :class="`ml-3 font-bold ${isMobile ? 'font-12' : 'font-15'}`">Shot</span>
            <div class="mt-2 input-normal d-flex justify-content-between vertical-center pointer" @click="openDrinkDialog(true)">
              <img :src="getDrinkIcon(parentInfo1.category)" :height="20" v-if="parentInfo1" />
              <span class="ml-5 mr-auto font-12">{{ parentInfo1 ? parentInfo1.name : '' }}</span>
              <i :class="`${isMobile ? 'font-12' : 'font-15'} fa fa-angle-down`" />
            </div>
          </div>
          <div :class="`mt-5 d-flex flex-column ${isWide ? 'w-50 ml-2' : 'w-100'}`">
            <span :class="`ml-3 font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('non_alcohol') }}</span>
            <div class="mt-2 input-normal d-flex justify-content-between vertical-center pointer" @click="openDrinkDialog(false)">
              <img :src="getDrinkIcon(parentInfo2.category)" :height="20" v-if="parentInfo2" />
              <span class="ml-5 mr-auto font-12">{{ parentInfo2 ? parentInfo2.name : '' }}</span>
              <i :class="`${isMobile ? 'font-12' : 'font-15'} fa fa-angle-down`" />
            </div>
          </div>
        </div>
        <span :class="`ml-3 mt-5 font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('drink_name') }}</span>
        <input class="mt-2 input-normal" v-model="drinkName" />
        <div class="ml-3 mt-5 vertical-center pointer" @click="gotoEmployees()">
          <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('alert_assign_inventory_to_employees') }}</span>
          <i :class="`${isMobile ? 'font-12' : 'font-20'} ml-2 color-icon fa fa-angle-right`" />
        </div>
        <div class="ml-3 mt-5 d-flex justify-content-between vertical-center pointer" @click="visibleMyTicket = !visibleMyTicket">
          <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('show_or_hide_organizer') }}</span>
          <img :src="visibleMyTicket ? assets.switch_on : assets.switch_off" :height="isWide ? 25 : 15" />
        </div>
        <div class="my-2 justify-content-between vertical-center" v-for="(item, index) in quantityKeyList" :key="'employeeInfo' + index">
          <span v-if="index !== 0 || (index === 0 && visibleMyTicket)">&nbsp;</span>
          <div :class="`${isWide ? 'w-75' : 'w-100'} d-flex justify-content-between vertical-center`" v-if="index !== 0 || (index === 0 && visibleMyTicket)">
            <span :class="`ml-3 mt-5 font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ item.name }}</span>
            <span class="ml-auto mr-2 font-12" v-if="drinkName">{{ `${soldQuantityMap[item.key] || 0} / ` }}</span>
            <input class="w-25 input-normal" v-model="totalQuantityMap[item.key]" maxlength="5" @input="onQuantityInput(item.key, $event.target.value)" :disabled="isLongDrinks()" />
          </div>
        </div>
        <div class="mt-5 d-flex justify-content-between vertical-center">
          <div class="d-flex flex-column w-50 mr-2">
            <span :class="`ml-3 font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('price') }}</span>
            <div class="mt-2 input-group">
              <input class="input-normal form-control" v-model="drinkPrice" maxlength="8" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\.\d{2}).+/g, '$1');" :disabled="isLongDrinks()" />
              <div class="input-group-append">
                <span class="input-group-text">€</span>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column w-50 ml-2">
            <span :class="`ml-3 font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('amount') }}</span>
            <input class="mt-2 input-normal" v-model="totalQuantity" disabled />
          </div>
        </div>
        <div class="my-5 d-flex justify-content-end">
          <v-btn class="ml-auto mt-5 button-normal" @click="saveDrink()">
            <img :src="drinkId ? assets.check : assets.plus" :width="20" />
          </v-btn>
        </div>
      </div>
      <div :class="`box-white d-flex flex-column ${isWide ? 'mt-10 p-10' : isMobile ? 'mt-5 mx-5 p-5' : 'mt-7 p-10'}`">
        <div :class="`my-5 d-flex ${isWide ? 'justify-content-between vertical-center' : 'flex-column'}`">
          <span :class="`font-bold ${isWide ? 'w-50 mr-2 font-15' : isMobile ? 'mb-2 font-12' : 'mb-2 font-15'}`">{{ $t('select_drink_category') }}</span>
          <b-form-select v-model="selectedCategory">
            <b-form-select-option v-for="category in [$t('all'), ...categories]" :value="category" :key="category">{{ category }}</b-form-select-option>
          </b-form-select>
        </div>
        <div class="my-3 box-drink justify-content-between vertical-center" v-for="(item, index) in getFilteredDrinkList()" :key="(item, index)">
          <img class="bg-drink-icon" :src="getDrinkIcon(item.category)" :height="isMobile ? 35 : 50" />
          <div class="w-100 mx-5 d-flex flex-column">
            <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ item.name }}</span>
            <div class="mt-2 d-flex justify-content-between vertical-center">
              <span :class="isMobile ? 'font-12' : 'font-15'">{{ getPriceFormat(item.price) }}</span>
              <span :class="`ml-auto ${isMobile ? 'font-12' : 'font-15'}`">{{ `${getDecimalFormat(item.soldQuantity)} / ${getDecimalFormat(item.totalQuantity)}` }}</span>
            </div>
          </div>
          <v-btn class="box-button mr-3" icon small @click="editDrink(item)" v-if="item.drinkId && selectedTag === eventInfo.tag">
            <i :class="`fa fa-edit color-pink ${isMobile ? 'font-12' : 'font-15'}`"></i>
          </v-btn>
          <v-btn class="box-button" icon small @click="deleteDrinkInfoTask(index, item.drinkId)" v-if="selectedTag === eventInfo.tag">
            <i :class="`flaticon-delete-1 color-red ${isMobile ? 'font-12' : 'font-15'}`"></i>
          </v-btn>
        </div>
      </div>
      <div class="my-20 d-flex justify-content-center" v-if="selectedTag === eventInfo.tag">
        <v-btn class="button-normal" @click="setDrinkInfoTask()">
          <span :class="`${isMobile ? 'mx-20' : 'mx-40'}`">{{ $t(`${isUpdate ? 'save' : 'add'}`) }}</span>
        </v-btn>
      </div>
    </div>
    <div class="my-30 py-40 center" v-else>
      <b-spinner type="grow"></b-spinner>
      <p class="mt-5">{{ $t('alert_please_wait') }}</p>
    </div>
    <v-dialog v-model="visibleDrinkDialog" scrollable width="400px">
      <v-card>
        <v-card-title class="bg-pink d-flex justify-content-between">
          <span :class="`font-bold color-white ${isMobile ? 'font-15' : 'font-18'}`">{{ $t(isShot ? 'select_shot' : 'select_non_alcohol') }}</span>
          <v-btn class="m-1" icon small @click="visibleDrinkDialog = false">
            <i class="flaticon-cancel font-bold font-25 color-white"></i>
          </v-btn>
        </v-card-title>
        <v-card-text class="mb-10">
          <div class="my-5 box-white justify-content-between vertical-center pointer" v-for="(item, index) in (isShot ? shotList : nonAlcoholList).filter(element => element.tag === selectedTag)" :key="(item, index)" @click="selectParent(item)">
            <img :src="getDrinkIcon(item.category)" :height="40" />
            <div class="mx-5 w-100 d-flex flex-column">
              <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ item.name }}</span>
              <div class="mt-2 d-flex justify-content-between vertical-center">
                <span :class="isMobile ? 'font-12' : 'font-15'">{{ getPriceFormat(item.price) }}</span>
                <span :class="`ml-auto ${isMobile ? 'font-12' : 'font-15'}`">{{ `${getDecimalFormat(item.soldQuantity)} / ${getDecimalFormat(item.totalQuantity)}` }}</span>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="visibleSelectTagDialog" :width="isWide ? '800px' : ''">
      <v-card style="min-height: 500px;">
        <v-card-title class="bg-pink d-flex justify-content-between">
          <span :class="`font-bold color-white ${isMobile ? 'font-15' : 'font-18'}`">{{ $t('tag') }}</span>
          <v-btn class="m-1" icon small @click="closeSelectTagDialog('')">
            <i class="flaticon-cancel font-bold font-25 color-white"></i>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="m-5 d-flex flex-column">
            <div class="mb-5 w-100 input-with-icon d-flex justify-content-between vertical-center">
              <i :class="`${isMobile ? 'font-15' : 'font-20'} fa fa-search color-icon`" />
              <input :class="`w-100 ${isMobile ? 'px-2' : 'px-5'}`" :placeholder="$t('search')" v-model="keywordTag" />
            </div>
            <div class="pointer d-flex flex-column" v-for="(tag, index) in getFilteredTagList()" :key="(tag, index)" @click="closeSelectTagDialog(tag)">
              <span :class="`my-3 tag-${tag === selectedTag ? 'selected' : 'normal'} font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ tag }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="visibleSetTagDialog" :width="isWide ? '800px' : ''" height="800px">
      <v-card>
        <v-card-title class="bg-pink d-flex justify-content-between">
          <span :class="`font-bold color-white ${isMobile ? 'font-15' : 'font-18'}`">{{ $t('set_new_tag') }}</span>
          <v-btn class="m-1" icon small @click="visibleSetTagDialog = false;">
            <i class="flaticon-cancel font-bold font-25 color-white"></i>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="m-5 d-flex flex-column">
            <span class="mt-5 font-12">{{ $t('alert_set_new_tag_instruction') }}</span>
            <span class="mt-10 font-label">{{ $t('tag') }}</span>
            <input class="mt-2 input-normal" v-model="newTag" />
            <div class="my-5 d-flex justify-content-end">
              <v-btn class="ml-auto button-normal" @click="setDrinkEventTagTask()">
                <span class="mx-10">{{ $t('ok') }}</span>
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
  @import "@/assets/sass/pages/edit_drink.scss";
</style>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions, firestore } from '../../../main';
import { onSnapshot, query, collection, where } from 'firebase/firestore';
import { toInt, getRoundValue, getDecimalFormat, getPriceFormat, showLoading, showFunctionError } from '../../../functions';

import plus from '@/assets/images/plus.png';
import check from '@/assets/images/check.png';
import switch_on from '@/assets/images/switch_on.png';
import switch_off from '@/assets/images/switch_off.png';

import drink_longdrinks from '@/assets/images/drink/Longdrinks.png';
import drink_shots from '@/assets/images/drink/Shots.png';
import drink_cocktail from '@/assets/images/drink/Cocktail.png';
import drink_spirituosen from '@/assets/images/drink/Spirituosen.png';
import drink_champagner from '@/assets/images/drink/Champagner.png';
import drink_wein from '@/assets/images/drink/Wein.png';
import drink_sonstiges from '@/assets/images/drink/Sonstiges.png';
import drink_bier from '@/assets/images/drink/Bier.png';
import drink_alkoholfreie from '@/assets/images/drink/Alkoholfreie Getränke.png';

export default {
  name: 'EditDrink',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    },
    myInfo() {
      return this.$store.state.myInfo;
    },
    myEventList() {
      return this.$store.state.myEventList;
    },
    quantityKeyList() {
      const list = [];
      list.push({
        key: this.$store.state.myId,
        name: this.myInfo.userName
      });
      const employeeList = this.$store.state.employeeList.filter(element => element.inUse === true && element.role === 2 && element.eventId === this.$route.params.eventId);
      employeeList.sort((a, b) => (a.name > b.name) ? 1 : -1);
      employeeList.forEach(employeeInfo => {
        list.push({
          key: employeeInfo.name,
          name: employeeInfo.name
        });
      });
      return list;
    }
  },
  watch: {
    myEventList() {
      this.checkEvent();
    },
    selectedTag() {
      this.getDrinkListTask();
    }
  },
  data() {
    return {
      categories: ['Longdrinks', 'Shots', 'Bier', 'Cocktail', 'Wein', 'Spirituosen', 'Champagner', 'Sonstiges', 'Alkoholfreie Getränke'],
      assets: {
        plus,
        check,
        switch_on,
        switch_off
      },
      isUpdate: false,
      eventInfo: null,
      selectedTag: '',
      tagList: [],
      drinkId: '',
      category: '',
      drinkName: '',
      drinkPrice: '',
      totalQuantity: 0,
      soldQuantity: 0,
      drinkList: null,
      shotList: [],
      nonAlcoholList: [],
      visibleDrinkDialog: false,
      isShot: false,
      visibleSelectTagDialog: false,
      visibleSetTagDialog: false,
      keywordTag: '',
      newTag: '',
      soldQuantityMap: {},
      totalQuantityMap: {},
      parentInfo1: null,
      parentInfo2: null,
      visibleMyTicket: false,
      selectedCategory: this.$t('all'),
      snapDrinkList: null
    };
  },
  mounted() {
    if (this.checkPermission()) {
      this.checkEvent();
    }
  },
  destroyed() {
    if (this.snapDrinkList) {
      this.snapDrinkList();
    }
  },
  methods: {
    getDecimalFormat,
    getPriceFormat,
    checkPermission() {
      if (this.$route.params.eventId) {
        if (this.myInfo) {
          if (this.myInfo.userType === this.USER_ORGANIZER) {
            return true;
          } else {
            this.$toast.error('No permission!!');
            this.$router.push('/');
            return false;
          }
        } else {
          this.$toast.info(this.$t('alert_sign_in'));
          this.$router.push('/');
          return false;
        }
      } else {
        this.$router.push('/');
      }
    },
    checkEvent() {
      if (this.myEventList) {
        const needLoadData = this.eventInfo === null;
        const eventInfo = this.myEventList.find(element => element.eventId === this.$route.params.eventId);
        if (eventInfo) {
          if (eventInfo.userId !== this.$store.state.myId) {
            this.$toast.error('This is not your event!!');
            this.$router.push('/my_events/1');
          } else {
            if (eventInfo.tag && eventInfo.tagList && eventInfo.tagList.length !== this.tagList.length) {
              this.tagList = [];
              eventInfo.tagList.forEach(element => this.tagList.push(element));
              this.tagList.reverse();
              this.selectedTag = eventInfo.tag;
            }
            if (!eventInfo.hasOwnProperty('tag')) {
              eventInfo['tag'] = '';
            }
            this.eventInfo = eventInfo;
            if (needLoadData && !this.selectedTag) {
              this.getDrinkListTask();
            }
          }
        } else {
          this.$toast.error('Invalid event!');
          this.$router.push('/my_events/1');
        }
      }
    },
    checkTag() {
      if (this.eventInfo) {
        if (this.eventInfo.tag) {
          return this.eventInfo.tag === this.selectedTag;
        }
        return true;
      }
      return false;
    },
    getDrinkIcon(name) {
      switch (name) {
        case 'Longdrinks':
          return drink_longdrinks;
        case 'Shots':
          return drink_shots;
        case 'Cocktail':
          return drink_cocktail;
        case 'Spirituosen':
          return drink_spirituosen;
        case 'Champagner':
          return drink_champagner;
        case 'Wein':
          return drink_wein;
        case 'Sonstiges':
          return drink_sonstiges;
        case 'Bier':
          return drink_bier;
        case 'Alkoholfreie Getränke':
          return drink_alkoholfreie;
        default:
          return '';
      }
    },
    onQuantityInput(key, value) {
      this.totalQuantityMap[key] = value.replace(/[^0-9]/g,'');
      var totalQuantity = 0;
      this.quantityKeyList.forEach(info => {
        totalQuantity += toInt(this.totalQuantityMap[info.key] || 0);
      });
      this.totalQuantity = totalQuantity;
    },
    clearQuantityMap() {
      this.quantityKeyList.forEach(info => {
        this.totalQuantityMap[info.key] = '';
      });
    },
    isLongDrinks() {
      return this.category === this.categories[0];
    },
    gotoEmployees() {
      localStorage.setItem('eventboxEditDrinkEventId', this.$route.params.eventId);
      this.$router.push(`/employees`);
    },
    openDrinkDialog(isShot) {
      this.isShot = isShot;
      this.visibleDrinkDialog = true;
    },
    closeSelectTagDialog(tag) {
      if (tag) {
        this.selectedTag = tag;
      }
      this.visibleSelectTagDialog = false;
    },
    getFilteredDrinkList() {
      return this.drinkList.filter(element => (this.selectedCategory === this.$t('all') || element.category === this.selectedCategory) && element.tag === this.selectedTag);
    },
    getFilteredTagList() {
      return this.tagList.filter(element => element.toLowerCase().includes(this.keywordTag.toLowerCase()));
    },
    selectParent(drinkInfo) {
      if (this.isShot) {
        this.parentInfo1 = drinkInfo;
      } else {
        this.parentInfo2 = drinkInfo;
      }
      if (this.parentInfo1 && this.parentInfo2) {
        this.drinkPrice = getRoundValue((this.parentInfo1.price * 2 + this.parentInfo2.price) / 100);
        var totalQuantity = 0;
        this.quantityKeyList.forEach(info => {
          const quantity = Math.min(toInt(this.parentInfo1.totalQuantityMap[info.key] || 0), toInt(this.parentInfo2.totalQuantityMap[info.key] || 0));
          this.totalQuantityMap[info.key] = quantity;
          totalQuantity += quantity;
        });
        this.totalQuantity = totalQuantity;
      } else {
        this.drinkPrice = '';
        this.totalQuantity = '';
      }
      this.visibleDrinkDialog = false;
    },
    saveDrink() {
      if (!this.checkPermission()) {
        return;
      }
      if (!this.checkTag()) {
        this.$toast.info(this.$t('alert_tag_is_expired'));
        return;
      }
      var totalQuantity = 0;
      const totalQuantityMap = {};
      this.quantityKeyList.forEach(info => {
        const quantity = toInt(this.totalQuantityMap[info.key] || 0);
        totalQuantityMap[info.key] = quantity;
        totalQuantity += quantity;
      });
      const drinkInfo = {
        drinkId: this.drinkId,
        category: this.category,
        name: this.drinkName,
        price: getRoundValue(this.drinkPrice) * 100,
        tag: this.selectedTag,
        totalQuantity: totalQuantity,
        soldQuantity: this.soldQuantity,
        totalQuantityMap: totalQuantityMap
      }
      if (!drinkInfo.category) {
        this.$toast.error(this.$t('alert_select_category'));
        return;
      }
      if (this.isLongDrinks()) {
        if (!this.parentInfo1) {
          this.$toast.error(this.$t('alert_select_shot'));
          return;
        }
        if (!this.parentInfo2) {
          this.$toast.error(this.$t('alert_select_non_alcohol'));
          return;
        }
        drinkInfo['parentIDs'] = [this.parentInfo1.drinkId, this.parentInfo2.drinkId];
      }
      if (!drinkInfo.name) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('drink_name')]));
        return;
      }
      if (!drinkInfo.totalQuantity) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('amount')]));
        return;
      }
      if (!drinkInfo.price) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('price')]));
        return;
      }
      if (drinkInfo.drinkId) {
        const index = this.drinkList.findIndex(element => element.drinkId === drinkInfo.drinkId);
        if (index !== -1) {
          this.drinkList[index] = drinkInfo;
        }
      } else {
        this.drinkList.unshift(drinkInfo);
      }
      this.drinkList.filter(element => element.parentIDs && element.parentIDs.length === 2 && element.parentIDs.includes(this.drinkId)).forEach(childInfo => {
				const parentInfo1 = this.drinkList.find(element => element.drinkId === childInfo.parentIDs[0]);
				const parentInfo2 = this.drinkList.find(element => element.drinkId === childInfo.parentIDs[1]);
				if (parentInfo1 != null && parentInfo2 != null) {
					childInfo.price = parentInfo1.price * 2 + parentInfo2.price;
          childInfo.totalQuantity = 0;
          childInfo.totalQuantityMap = {};
          this.quantityKeyList.forEach(info => {
            const quantity = Math.min(toInt(parentInfo1.totalQuantityMap[info.key] || 0), toInt(parentInfo2.totalQuantityMap[info.key] || 0));
            childInfo.totalQuantityMap[info.key] = quantity;
            childInfo.totalQuantity += quantity;
          });
				}
			});
      this.drinkId = '';
      this.drinkName = '';
      this.drinkPrice = '';
      this.totalQuantity = '';
      this.soldQuantity = 0;
      this.clearQuantityMap();
    },
    editDrink(drinkInfo) {
      if (!this.checkTag()) {
        this.$toast.info(this.$t('alert_tag_is_expired'));
        return;
      }
      this.drinkId = drinkInfo.drinkId;
      this.category = drinkInfo.category;
      this.drinkName = drinkInfo.name,
      this.drinkPrice = getRoundValue(drinkInfo.price / 100);
      this.totalQuantity = drinkInfo.totalQuantity;
      this.soldQuantity = drinkInfo.soldQuantity;
      this.totalQuantityMap = drinkInfo.totalQuantityMap || {};
      this.soldQuantityMap = drinkInfo.soldQuantityMap || {};
      if (drinkInfo.parentIDs && drinkInfo.parentIDs.length === 2) {
        this.parentInfo1 = this.shotList.find(element => element.drinkId === drinkInfo.parentIDs[0]);
        this.parentInfo2 = this.nonAlcoholList.find(element => element.drinkId === drinkInfo.parentIDs[1]);
      }
      const divCategory = document.getElementById(`divCategory_${this.isMobile ? 6 : 0}`);
      if (divCategory) {
        divCategory.scrollIntoView();
      }
    },
    getDrinkListTask() {
      if (!this.eventInfo) {
        return;
      }
      if (this.snapDrinkList) {
        this.snapDrinkList();
      }
      let q;
      if (this.selectedTag) {
        q = query(collection(firestore, 'drink'), where('eventId', '==', this.$route.params.eventId), where('tag', '==', this.selectedTag), where('deletedAt', '==', null));
      } else {
        q = query(collection(firestore, 'drink'), where('eventId', '==', this.$route.params.eventId), where('deletedAt', '==', null));
      }
      const loader = showLoading(this);
      this.snapDrinkList = onSnapshot(q, querySnapshot => {
        loader.hide();
        const drinkList = [];
        querySnapshot.forEach(doc => {
          const info = doc.data();
          if (!info.tag) {
            info['tag'] = '';
          }
          drinkList.push(info);
        });
        this.drinkList = drinkList;
        this.shotList = JSON.parse(JSON.stringify(drinkList.filter(element => element.category === this.categories[1])));
        this.nonAlcoholList = JSON.parse(JSON.stringify(drinkList.filter(element => element.category === this.categories[8])));
        if (drinkList.length > 0) {
          this.isUpdate = true;
        }
      });
    },
    deleteDrinkInfoTask(index, drinkId) {
      if (drinkId) {
        if (!this.checkPermission()) {
          return;
        }
        if (!this.checkTag()) {
          this.$toast.info(this.$t('alert_tag_is_expired'));
          return;
        }
        if (!confirm(this.$t('confirm_delete'))) {
          return;
        }
        const params = {
          userId: this.$store.state.myId,
          isDelete: true,
          drinkId: drinkId
        }
        const loader = showLoading(this, 1);
        const func = httpsCallable(functions, 'setDrinkInfo');
        func(JSON.stringify(params)).then(response => {
          loader.hide();
          if (response.data !== this.RESULT_SUCCESS) {
            showFunctionError(this, response.data);
          }
        }).catch(error => {
          loader.hide();
          this.$toast.error(error.code + ', ' + error.message);
        });
      } else {
        this.drinkList.splice(index, 1);
      }
    },
    setDrinkInfoTask() {
      if (!this.checkPermission()) {
        return;
      }
      if (!this.checkTag()) {
        this.$toast.info(this.$t('alert_tag_is_expired'));
        return;
      }
      const drinkList = this.drinkList.filter(element => element.tag === this.selectedTag)
      if (drinkList.length === 0) {
        this.$toast.error(this.$t('alert_add_drinks_menu'));
        return;
      }
      const params = {
        userId: this.$store.state.myId,
        eventId: this.$route.params.eventId,
        drinkList: drinkList
      }
      const origList = this.drinkList.filter(element => element.drinkId);
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'setDrinkInfo');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          if (origList) {
            this.$toast.success(this.$t('alert_updated_successfully'));
          } else {
            this.$toast.success(this.$t('alert_added_successfully'));
          }
          this.$router.push(`/my_events/1`);
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    handleVisibleChange(event) {
      const value = event.target.checked;
      this.visibleMyTicket = value;
    },
    setDrinkEventTagTask() {
      if (!this.checkPermission()) {
        return;
      }
      if (!this.newTag) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('tag')]));
        return;
      }
      const params = {
        userId: this.$store.state.myId,
        eventId: this.$route.params.eventId,
        newTag: this.newTag
      }
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'duplicateDrinkInfo');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.selectedTag = this.newTag;
          this.newTag = '';
          this.visibleSetTagDialog = false;
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>